/* import {
  InteractionRequiredAuthError,
  InteractionType,
} from '@azure/msal-browser'; */
import { Client } from '@microsoft/microsoft-graph-client';

// import { msalInstance } from './index';

let graphClient = undefined;

function ensureClient(authProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });
  }

  return graphClient;
}

// get users from graph api.
export async function getUsers(authProvider) {
  ensureClient(authProvider);

  try {
    let response = await graphClient
      .api(`/users?$top=250`)
      .version('beta')
      .get();

    return response;
  } catch (error) {
    console.log(error);
  }
}

// Parallel calls to update selected users with inputs from form.
export async function updateUsers(authProvider, editData, userIds) {
  ensureClient(authProvider);

  let promises = [];

  for (let userId of userIds) {
    let promise = graphClient
      .api(`/users/${userId}`)
      .version('beta')
      .update(editData);
    promises.push(promise);
  }

  try {
    let response = await Promise.all(promises);
    return response;
  } catch (error) {
    console.log(error);
  }
}

/* export async function editSingleUser(authProvider, editData, userId) {
  ensureClient(authProvider);

  
  // const testUser2 = 'b5f9aed6-37dc-4531-b788-e87939624e56'
  const testUser1 = 'f1d7c64c-1837-49fd-8db1-c77fffee317d';
  // Body of update request
  const body = editData;

  try {
    let response = await graphClient
      .api(`/users/${userId}`)
      .version('beta')
      .update(body);
    return response;
  } catch (error) {
    console.log(error);
  }
} */
