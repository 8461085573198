import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { EditProvider } from './context/EditContext';
import { GlobalProvider } from './context/GlobalContext';

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    // Set the active account - this simplifies token acquisition
    const authResult = event.payload;
    msalInstance.setActiveAccount(authResult.account);
  }
});
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <GlobalProvider>
        <EditProvider>
          <App />
        </EditProvider>
      </GlobalProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
