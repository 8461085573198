import React, { useEffect, useState, useContext } from 'react';
import {
  useRowSelect,
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from 'react-table';
import { Link } from 'react-router-dom';
import Checkbox from './Checkbox';
import { GlobalFilter, DefaultColumnFilter } from './TableFilters';
import ToolbarModal from './ToolbarModal';
import ToggleSwitch from './ToggleSwitch';
import { GlobalContext } from '../context/GlobalContext';

import { matchSorter } from 'match-sorter';
import {
  IoMdCreate,
  IoMdAdd,
  IoMdTrash,
  IoIosArrowDown,
  IoIosArrowUp,
} from 'react-icons/io';
import { VscSettings } from 'react-icons/vsc';

import { MdUnfoldMore } from 'react-icons/md';

import styles from './css/table.module.css';

function fuzzyTextFilterFunc(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFunc.autoRemove = (val) => !val;

const Table = ({ columns, data, setSelectedRows }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isToggled } = useContext(GlobalContext);

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFunc,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    selectedFlatRows,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.accessor),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),

          Cell: ({ row }) => (
            <div>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    setSelectedRows(selectedFlatRows);
    // console.log(allColumns);
    // eslint-disable-next-line
  }, [setSelectedRows, selectedFlatRows]);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.filterWrapper}>
        <div className={styles.searchContainer}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <div className={styles.toggleContainer}>
          <span
            className={
              isToggled ? styles.toggleTitle_disabled : styles.toggleTitle
            }
          >
            All users
          </span>
          <ToggleSwitch />
          <span
            className={
              !isToggled ? styles.toggleTitle_disabled : styles.toggleTitle
            }
          >
            Active users
          </span>
        </div>
      </div>
      <div className={styles.toolbarContainer}>
        {isOpen && (
          <ToolbarModal setIsOpen={setIsOpen}>
            <div style={{ textAlign: 'left' }}>
              {allColumns.map((column) =>
                column.id !== 'selection' ? (
                  <div
                    key={column.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <input
                      type="checkbox"
                      {...column.getToggleHiddenProps({
                        title: 'Toggle Column Visibility',
                      })}
                      style={{
                        display: 'inline-block',
                        margin: '3px',
                        cursor: 'pointer',
                      }}
                    />{' '}
                    <span style={{ marginLeft: '5px' }}>{column.Header}</span>
                  </div>
                ) : null
              )}
            </div>
          </ToolbarModal>
        )}

        <div className={styles.linkDiv}>
          <Link
            className={
              styles.toolbarLink_disabled
            }
            to="/edit-user"
          >
            <IoMdCreate className={styles.toolbarIcon} /> Edit Users (
            {selectedFlatRows.length})
          </Link>
        </div>
        <div className={styles.linkDiv}>
          <Link
            className={
              styles.toolbarLink_disabled
            }
            to="/add-user"
          >
            <IoMdAdd className={styles.toolbarIcon} /> Add User
          </Link>
        </div>
        <div className={styles.linkDiv}>
          <Link
            className={
              styles.toolbarLink_disabled
            }
            to="/delete-user"
          >
            <IoMdTrash className={styles.toolbarIcon} /> Delete Users
          </Link>
        </div>
        <div className={styles.linkDiv}>
          <button className={styles.toolbarBtn} onClick={() => setIsOpen(true)}>
            <VscSettings className={styles.toolbarIcon} /> Adjust Columns
          </button>
        </div>
      </div>

      <table className={styles.userTable} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div
                    {...column.getSortByToggleProps({ title: 'Sort Column' })}
                    style={{
                      cursor: 'pointer',
                      minHeight: 30,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {column.render('Header')}
                    {column.canSort ? (
                      <span className={styles.sortIconSpan}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <>
                              <span className={styles.sortIcon}>
                                <IoIosArrowDown />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className={styles.sortIcon}>
                                <IoIosArrowUp />
                              </span>
                            </>
                          )
                        ) : (
                          <>
                            <span className={styles.defaultSortIcon}>
                              <MdUnfoldMore />
                            </span>
                          </>
                        )}
                      </span>
                    ) : null}
                  </div>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      onClick={() => {
                        row.toggleRowSelected();
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
