import React, { createContext, useState, useEffect } from 'react';

// import { useMsal } from '@azure/msal-react';
// import { InteractionType } from '@azure/msal-browser';
// import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';

// import { loginRequest } from '../authConfig';

// import { getUsers } from '../graphService';

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [userData, setUserData] = useState([]);
  const [isToggled, setIsToggled] = useState(true);
  const [toggleData, setToggleData] = useState([]);

  /* const msal = useMsal();
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance,
    {
      account: msal.instance.getActiveAccount(),
      scopes: loginRequest.scopes,
      interactionType: InteractionType.Popup,
    }
  ); */

  useEffect(() => {
    if (toggleData.length < 1 && userData.length > 1) {
      setToggleData(userData);
      console.log('toggleData');
    }

    // eslint-disable-next-line
  }, [isToggled]);

  useEffect(() => {
    // getUsers(authProvider).then((response) => setUserData(response.value));
    console.log(isToggled);
    /* console.log(toggleData);
    console.log(userData); */
    if (isToggled) {
      setUserData(userData.filter((user) => user.assignedLicenses.length > 0));
    } else if (!isToggled) {
      setUserData(toggleData);
    }
    // eslint-disable-next-line
  }, [isToggled]);

  return (
    <GlobalContext.Provider
      value={{ userData, setUserData, isToggled, setIsToggled }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
