import React from 'react';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { loginRequest } from '../authConfig';

import GeshdoLogoBlack from '../assets/logo/geshdo_black.png';
import GeshdoLogoWhite from '../assets/logo/geshdo_white.png';
import styles from './css/navbar.module.css';

export const PageLayout = () => {
  // const isAuthenticated = useIsAuthenticated();
  // {isAuthenticated ? <SignOutButton /> : <SignInButton />}

  return (
    <>
      <nav>
        <div className={styles.navContainer}>
          <div className={styles.navLogo}>
            <img src={GeshdoLogoWhite} alt="logo" className={styles.logoImg} />{' '}
            Crowdcontrol
          </div>
          <SignInButton />
        </div>
      </nav>

      <br />
      <br />
    </>
  );
};

const SignInButton = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  function handleLogin(instance) {
    !isAuthenticated
      ? instance.loginRedirect(loginRequest).catch((e) => {
          console.error(e);
        })
      : instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
  }

  if (!isAuthenticated) {
    return (
      <button
        className={styles.loginButton}
        onClick={() => handleLogin(instance)}
      >
        Sign In
      </button>
    );
  } else {
    return (
      <button
        className={styles.loginButton}
        onClick={() => handleLogin(instance)}
      >
        Sign Out
      </button>
    );
  }
};
