import React from 'react';
import EditUserForm from '../components/EditForm';
import EditUserList from '../components/EditUserList';

import styles from '../components/css/editUserView.module.css';

const EditUserView = () => {
  /* useEffect(() => {
    
  }, []); */
  return (
    <div className={styles.mainContainer}>
      <div className={styles.sidebarContainer}>
        <EditUserList />
      </div>
      <div className={styles.formContainer}>
        <EditUserForm />
      </div>
    </div>
  );
};

export default EditUserView;
