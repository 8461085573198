export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const graphConfig = {
  graphUsersEndpoint: 'https://graph.microsoft.com/beta/users?$top=150',
  graphPatchEndpoint:
    'https://graph.microsoft.com/beta/users/{f1d7c64c-1837-49fd-8db1-c77fffee317d}',
};

/* export const AuthProvider = () => {
  const msal = useMsal(),
    authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      msal.instance,
      {
        account: msal.instance.getActiveAccount(),
        scopes: loginRequest.scopes,
        interactionType: InteractionType.Popup,
      }
    );

  return authProvider;
}; */
