import React from 'react';
import { RiCloseLine } from 'react-icons/ri';
import styles from './css/toolbarModal.module.css';

const ToolbarModal = ({ setIsOpen, children }) => {
  return (
    <>
      <div className={styles.transparentBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h6 className={styles.heading}>Adjust Columns</h6>
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: '-3px' }} />
          </button>

          <div className={styles.modalColSelect}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default ToolbarModal;
