import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from './authConfig';

import { PageLayout } from './components/Navbar';
import { TableView } from './views/TableView';
import EditUserView from './views/EditUserView';
import Spinner from './components/Spinner';

import './App.css';

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }

  return (
    <>
      <AuthenticatedTemplate>
        <Router>
          <PageLayout />
          <div className="App">
            <Routes>
              <Route path="/" element={<TableView />} />
              <Route path="/edit-user" element={<EditUserView />} />
            </Routes>
          </div>
        </Router>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div className="pos-center">
          <Spinner />
        </div>
      </UnauthenticatedTemplate>
    </>
  );
}

/* <div className="App">
      <AuthenticatedTemplate>
        <PageLayout />
        <UserTable />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <PageLayout />
        <h4>Please sign in to view content.</h4>
      </UnauthenticatedTemplate>
    </div> */

export default App;
