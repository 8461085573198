import { SelectColumnFilter } from './TableFilters';

export const COLUMNS = [
  {
    Header: 'Name',
    accessor: 'displayName',
    filter: 'fuzzyText',
  },
  {
    Header: 'User Type',
    accessor: 'userType',
    Filter: SelectColumnFilter,
    filter: 'includes',
  },
  {
    Header: 'Email',
    accessor: 'mail',
  },
  {
    Header: 'Phone Number',
    accessor: 'mobilePhone',
    filter: 'fuzzyText',
    disableFilters: false,
  },

  {
    Header: 'Job Title',
    accessor: 'jobTitle',
    Filter: SelectColumnFilter,
    disableFilters: false,
  },
  {
    Header: 'Preferred Language',
    accessor: 'preferredLanguage',
    Filter: SelectColumnFilter,
    disableFilters: false,
    show: false,
  },

  {
    Header: 'Department',
    accessor: 'department',
    Filter: SelectColumnFilter,
    disableFilters: false,
  },
  {
    Header: 'Company',
    accessor: 'companyName',
    Filter: SelectColumnFilter,
    disableFilters: false,
    show: false,
  },
  {
    Header: 'Location',
    accessor: 'officeLocation',
    filter: 'fuzzyText',
    disableFilters: false,
  },
  {
    Header: 'Street Address',
    accessor: 'streetAddress',
    Filter: SelectColumnFilter,
    disableFilters: false,
    show: false,
  },
  {
    Header: 'Postal Code',
    accessor: 'postalCode',
    Filter: SelectColumnFilter,
    disableFilters: false,
    show: false,
  },
  {
    Header: 'City',
    accessor: 'city',
    Filter: SelectColumnFilter,
    disableFilters: false,
    show: false,
  },
  {
    Header: 'Creation Time',
    accessor: 'createdDateTime',
    disableFilters: false,
    show: false,
  },
];
