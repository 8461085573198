import React, { createContext, useState } from 'react';

export const EditContext = createContext({
  rows: [],
  setRows: () => {},
});

export const EditProvider = ({ children }) => {
  const [rows, setRows] = useState([]);

  return (
    <EditContext.Provider value={{ rows, setRows }}>
      {children}
    </EditContext.Provider>
  );
};
