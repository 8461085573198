import React, { useContext, useEffect } from 'react';

import { EditContext } from '../context/EditContext';

import { IoClose } from 'react-icons/io5';

import styles from '../components/css/editUserList.module.css';

const EditUserList = () => {
  const { rows, setRows } = useContext(EditContext);

  useEffect(() => {
    // setSavedRows(rows);
    //console.log(rows);
  }, []);

  const deleteCard = (id) => {
    setRows(rows.filter((item) => item.id !== id));
  };

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.listTitle}> Users to edit ({rows.length})</h4>
      <div className={styles.userListContainer}>
        {rows && (
          <div className={styles.col}>
            {rows.map((rows) => (
              <div className={styles.cardBody} key={rows.id}>
                <div className={styles.cardContent}>
                  <h5 className={styles.cardTitle}>{rows.displayName}</h5>
                  <p className={styles.cardText}>{rows.department}</p>
                </div>
                <div className={styles.cardDelete}>
                  <button
                    className={styles.deleteBtn}
                    onClick={() => deleteCard(rows.id)}
                  >
                    <IoClose />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditUserList;
