import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';

import { EditContext } from '../context/EditContext';
import { GlobalContext } from '../context/GlobalContext';
import { updateUsers } from '../graphService';
import { loginRequest } from '../authConfig';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FiAlertCircle } from 'react-icons/fi';
import styles from './css/editForm.module.css';

toast.configure({});

const EditUserForm = () => {
  const { rows } = useContext(EditContext);
  const { userData, setUserData } = useContext(GlobalContext);
  const [userIds, setUserIds] = useState([]);
  const [inputs, setInputs] = useState({});

  const navigate = useNavigate();

  const msal = useMsal();
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance,
    {
      account: msal.instance.getActiveAccount(),
      scopes: loginRequest.scopes,
      interactionType: InteractionType.Popup,
    }
  );

  useEffect(() => {
    setUserIds(rows.map((row) => row.id));
    console.log(rows);
  }, [rows]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const submitForm = (event) => {
    event.preventDefault();

    updateUsers(authProvider, inputs, userIds).then((response) => {
      if (response.error) {
        toast.error('One or more of the updates failed. Please try again', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        });
      } else {
        toast.success('Users has been successfully edited!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        });
      }
    });

    const selUsers = [...userData];

    for (let i = 0; i < selUsers.length; i++) {
      const user = selUsers[i];

      for (let j = 0; j < userIds.length; j++) {
        if (selUsers[i].id === userIds[j]) {
          selUsers[i] = { ...user, ...inputs };
        }
      }
    }

    // console.log(selUsers);

    setUserData(selUsers);

    navigate('/');
  };

  const checkValues = (objKey) => {
    // console.log(objKey);
    const unique = [];
    const distinct = [];

    for (let i = 0; i < rows.length; i++) {
      if (!unique[rows[i][objKey]]) {
        distinct.push(rows[i][objKey]);
        unique[rows[i][objKey]] = 1;
      }
    }
    // console.log(unique);
    // console.log(distinct.length);
    if (distinct.length > 1) {
      return null;
    } else if (distinct.length === 1) {
      return distinct[0];
    }
  };

  return (
    <div className={styles.formWrapper}>
      <form autoComplete="off">
        <div className={styles.formContainer}>
          <div className={styles.formCol}>
            <div className={styles.inputDiv}>
              <label>Department</label>
              {checkValues('department') === null ? (
                <FiAlertCircle
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: '5px',
                    marginBottom: '2px',
                    fontSize: '18px',
                    color: 'orangered',
                  }}
                />
              ) : null}
              <input
                type="text"
                name="department"
                placeholder={
                  checkValues('department') === null
                    ? 'Current values is not identical!'
                    : checkValues('department')
                }
                value={inputs.department || ''}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputDiv}>
              <label>Job Title</label>
              {checkValues('jobTitle') === null ? (
                <FiAlertCircle
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: '5px',
                    marginBottom: '2px',
                    fontSize: '18px',
                    color: 'orangered',
                  }}
                />
              ) : null}
              <input
                type="text"
                name="jobTitle"
                placeholder={
                  checkValues('jobTitle') === null
                    ? 'Current values is not identical!'
                    : checkValues('jobTitle')
                }
                value={inputs.jobTitle || ''}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputDiv}>
              <label>Office Location</label>
              {checkValues('officeLocation') === null ? (
                <FiAlertCircle
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: '5px',
                    marginBottom: '2px',
                    fontSize: '18px',
                    color: 'orangered',
                  }}
                />
              ) : null}
              <input
                type="text"
                name="officeLocation"
                placeholder={
                  checkValues('officeLocation') === null
                    ? 'Current values is not identical!'
                    : checkValues('officeLocation')
                }
                value={inputs.officeLocation || ''}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.formCol}>
            <div className={styles.inputDiv}>
              <label>Street Address</label>
              {checkValues('streetAddress') === null ? (
                <FiAlertCircle
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: '5px',
                    marginBottom: '2px',
                    fontSize: '18px',
                    color: 'orangered',
                  }}
                />
              ) : null}
              <input
                type="text"
                name="streetAddress"
                placeholder={
                  checkValues('streetAddress') === null
                    ? 'Current values is not identical!'
                    : checkValues('streetAddress')
                }
                value={inputs.streetAddress || ''}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputDiv}>
              <label>Postal Code</label>
              {checkValues('postalCode') === null ? (
                <FiAlertCircle
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: '5px',
                    marginBottom: '2px',
                    fontSize: '18px',
                    color: 'orangered',
                  }}
                />
              ) : null}
              <input
                type="text"
                name="postalCode"
                placeholder={
                  checkValues('postalCode') === null
                    ? 'Current values is not identical!'
                    : checkValues('postalCode')
                }
                value={inputs.postalCode || ''}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputDiv}>
              <label>City</label>
              {checkValues('city') === null ? (
                <FiAlertCircle
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: '5px',
                    marginBottom: '2px',
                    fontSize: '18px',
                    color: 'orangered',
                  }}
                />
              ) : null}
              <input
                type="text"
                name="city"
                placeholder={
                  checkValues('city') === null
                    ? 'Current values is not identical!'
                    : checkValues('city')
                }
                value={inputs.city || ''}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.formCol}>
            <div className={styles.inputDiv}>
              <label>Company</label>
              {checkValues('companyName') === null ? (
                <FiAlertCircle
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: '5px',
                    marginBottom: '2px',
                    fontSize: '18px',
                    color: 'orangered',
                  }}
                />
              ) : null}
              <input
                type="text"
                name="companyName"
                placeholder={
                  checkValues('companyName') === null
                    ? 'Current values is not identical!'
                    : checkValues('companyName')
                }
                value={inputs.companyName || ''}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputDiv}>
              <label>Preferred Language</label>
              {checkValues('preferredLanguage') === null ? (
                <FiAlertCircle
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: '5px',
                    marginBottom: '2px',
                    fontSize: '18px',
                    color: 'orangered',
                  }}
                />
              ) : null}
              <input
                type="text"
                name="preferredLanguage"
                placeholder={
                  checkValues('preferredLanguage') === null
                    ? 'Current values is not identical!'
                    : checkValues('preferredLanguage')
                }
                value={inputs.preferredLanguage || ''}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputDiv}>
              <label>User Type</label>
              {checkValues('userType') === null ? (
                <FiAlertCircle
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: '5px',
                    marginBottom: '2px',
                    fontSize: '18px',
                    color: 'orangered',
                  }}
                />
              ) : null}
              <input
                type="text"
                name="userType"
                placeholder={
                  checkValues('userType') === null
                    ? 'Current values is not identical!'
                    : checkValues('userType')
                }
                value={inputs.userType || ''}
                onChange={handleChange}
                disabled
              />
            </div>
          </div>
        </div>
      </form>
      <div className={styles.btnContainer}>
        <button className={styles.btnBack} onClick={() => navigate('/')}>
          Go back without saving
        </button>
        <button className={styles.submitBtn} onClick={submitForm}>
          Save & edit {userIds.length} users
        </button>
      </div>
    </div>
  );
};

export default EditUserForm;
