import React, { useEffect, useState, useContext } from 'react';

import { useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';

import { loginRequest } from '../authConfig';

import { getUsers } from '../graphService';
import Table from '../components/Table';
import { COLUMNS } from '../components/Columns';
import styles from '../components/css/tableView.module.css';
import { EditContext } from '../context/EditContext';
import { GlobalContext } from '../context/GlobalContext';

export const TableView = () => {
  // const [cells, setCells] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { setRows } = useContext(EditContext);
  const { userData, setUserData } = useContext(GlobalContext);
  const msal = useMsal();

  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance,
    {
      account: msal.instance.getActiveAccount(),
      scopes: loginRequest.scopes,
      interactionType: InteractionType.Popup,
    }
  );

  useEffect(() => {
    // getUsers(authProvider).then((response) => setCells(response.value));
    if (userData.length < 1) {
      console.log('getUsers');
      getUsers(authProvider).then((response) => {
        const filteredUsers = response.value
          .filter((user) => user.assignedLicenses.length > 0)
          
        return setUserData(filteredUsers)
      });
    }

    // setCells(userData);
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    console.log(selectedRows.map((row) => row.original));
    setRows(selectedRows.map((row) => row.original));

    /* const selUsers = selectedRows.map((row) => row.original);

    for (let index = 0; index < selUsers.length; index++) {
      const user = selUsers[index];

      selUsers[index] = { ...user, isSelected: true };

    }
    setRows(selUsers);
    console.log(rows); */

    // eslint-disable-next-line
  }, [selectedRows]);

  const columns = React.useMemo(() => COLUMNS, []);
  const data = React.useMemo(() => userData, [userData]);

  return (
    <>
      <div className={styles.mainContainer}>
        {userData && (
          <Table
            columns={columns}
            data={data}
            setSelectedRows={setSelectedRows}
          />
        )}
      </div>
    </>
  );
};
