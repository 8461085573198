import React, { useContext } from 'react';

import { GlobalContext } from '../context/GlobalContext';

import styles from './css/toggleSwitch.module.css';

const ToggleSwitch = () => {
  const { isToggled, setIsToggled } = useContext(GlobalContext);
  /* const [toggleData, setToggleData] = useState([]);
  const { userData, setUserData } = useContext(GlobalContext); */

  /* useEffect(() => {
    if (toggleData < 1) {
      setToggleData(userData);
      console.log(toggleData);
    }
    // eslint-disable-next-line
  }, [toggleData]); */

  const onToggle = () => {
    setIsToggled(!isToggled);
    console.log('onToggle');
    /* if (!isToggled) {
      setUserData(userData.filter((user) => user.assignedLicenses.length > 0));
    } else if (isToggled) {
      setUserData(toggleData);
    } */
  };

  return (
    <label className={styles.toggleSwitch}>
      <input disabled type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className={styles.switch} />
    </label>
  );
};

export default ToggleSwitch;
